import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPayments } from "../../redux/slices/paymentsSlice";
import { fetchbranch, setSelectedbranch } from "../../redux/slices/branchSlice";
import { getCompanies } from "../../api/companies";
import { deletePayment, updatePayment, addPayment } from "../../api/payments";
import PaymentList from "../../components/Payment/PaymentList";
import Modal from "../../components/Modal";
import PaymentForm from "../../components/Payment/PaymentForm";
import Loader from "../../components/Loader";
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

const PaymentsPage = () => {
  const dispatch = useDispatch();
  const { payments, loading } = useSelector((state) => state.payments);
  const { list: branches, selectedbranch } = useSelector(
    (state) => state.branch
  );
  const { selectedPeriod } = useSelector((state) => state.period);

  const [companies, setCompanies] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isBranchDropdownOpen, setIsBranchDropdownOpen] = useState(false);

  const branchDropdownRef = useRef(null);

  const fetchCompanies = useCallback(async (branchId) => {
    if (!branchId) return;
    try {
      const companiesData = await getCompanies(branchId);
      setCompanies(companiesData);
    } catch (error) {
      console.error("Şirketler alınırken hata:", error);
    }
  }, []);

  const handleBranchSelect = (branch) => {
    if (selectedbranch?._id === branch._id) {
      setIsBranchDropdownOpen(false);
      return;
    }
    dispatch(setSelectedbranch(branch));
    setIsBranchDropdownOpen(false);
  };

  const handlePaymentDelete = async (paymentId) => {
    try {
      await deletePayment(paymentId);
      if (selectedbranch?._id && selectedPeriod?.name) {
        dispatch(
          fetchPayments({
            branchId: selectedbranch._id,
            year: selectedPeriod.name,
          })
        );
      }
      toast.success("Ödeme başarıyla silindi.");
    } catch (error) {
      console.error("Ödeme silinirken hata:", error);
      toast.error("Ödeme silinirken bir hata oluştu.");
    }
  };

  const handlePaymentUpdate = async (paymentId, updatedData) => {
    try {
      await updatePayment(paymentId, updatedData, selectedbranch._id);
      dispatch(
        fetchPayments({
          branchId: selectedbranch._id,
          year: selectedPeriod.name,
        })
      );
      toast.success("Ödeme başarıyla güncellendi.");
    } catch (error) {
      console.error("Ödeme güncellenirken hata:", error);
      toast.error("Ödeme güncellenirken bir hata oluştu.");
    }
  };

  const handleAddPayment = async (paymentData) => {
    try {
      await addPayment(paymentData, selectedbranch._id, paymentData.companyId);
      dispatch(
        fetchPayments({
          branchId: selectedbranch._id,
          year: selectedPeriod.name,
        })
      );
      setShowModal(false);
      toast.success("Ödeme başarıyla eklendi.");
    } catch (error) {
      console.error("Ödeme eklenirken hata:", error);
      toast.error("Ödeme eklenirken bir hata oluştu.");
    }
  };

  useEffect(() => {
    if (!branches.length) {
      dispatch(fetchbranch());
    }
  }, [branches.length, dispatch]);

  useEffect(() => {
    if (selectedbranch?._id && selectedPeriod?.name) {
      dispatch(
        fetchPayments({
          branchId: selectedbranch._id,
          year: selectedPeriod.name,
        })
      );
      fetchCompanies(selectedbranch._id);
    }
  }, [selectedbranch?._id, selectedPeriod?.name, dispatch, fetchCompanies]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        branchDropdownRef.current &&
        !branchDropdownRef.current.contains(event.target)
      ) {
        setIsBranchDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Helmet>
        <title>Ödeme Yönetimi - Etkin Cari</title>
        <meta
          name="description"
          content="Ödemelerinizi hızlı ve kolay bir şekilde takip edin."
        />
        <meta
          name="keywords"
          content="ödeme takibi, cari hesap, borç alacak, ticari ilişkiler"
        />
      </Helmet>
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold text-center text-blue-800 mb-4">
          Ödeme Listesi
        </h1>
        {selectedbranch ? (
          <>
            <div className="gap-4 flex">
              <button
                className="mb-4 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-300 hover:text-black transition"
                onClick={() => setShowModal(true)}
              >
                Ödeme Ekle
              </button>
            </div>
            <PaymentList
              payments={payments}
              companies={companies}
              onPaymentUpdate={handlePaymentUpdate}
              onPaymentDelete={handlePaymentDelete}
            />
          </>
        ) : (
          <div className="flex flex-col items-center justify-center min-h-[60vh] text-center">
            <div className="text-gray-500 mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-20 h-20"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 21h16.5M3.75 3v18m16.5-18v18M8.25 6h7.5M8.25 10.5h7.5M8.25 15h7.5"
                />
              </svg>
            </div>
            <h2 className="text-xl font-bold text-gray-800">
              Seçili bir şube bulunamadı
            </h2>
            <p className="text-gray-500 mt-2">
              Ödemeleri görüntülemek için bir şube seçmeniz gerekiyor.
            </p>
            <div className="relative mt-4" ref={branchDropdownRef}>
              <button
                onClick={() => setIsBranchDropdownOpen(!isBranchDropdownOpen)}
                className="bg-gray-700 text-white px-6 py-2 rounded-md hover:bg-gray-600 transition"
              >
                {selectedbranch ? selectedbranch.name : "Şube Seçiniz"}
              </button>
              {isBranchDropdownOpen && (
                <ul className="absolute left-0 mt-2 bg-white text-gray-800 shadow-lg rounded-md w-48 z-40">
                  {branches.length > 0 ? (
                    branches.map((branch) => (
                      <li key={branch._id}>
                        <button
                          onClick={() => handleBranchSelect(branch)}
                          className="w-full px-4 py-2 text-left hover:bg-gray-100"
                        >
                          {branch.name}
                        </button>
                      </li>
                    ))
                  ) : (
                    <li className="px-4 py-2 text-gray-500">Şube bulunamadı</li>
                  )}
                </ul>
              )}
            </div>
          </div>
        )}
        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <PaymentForm
            companies={companies}
            onAddPayment={handleAddPayment}
            closeModal={() => setShowModal(false)}
            selectedbranchId={selectedbranch?._id}
          />
        </Modal>
      </div>
    </>
  );
};

export default PaymentsPage;
