import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getInvoices, addInvoice, apiUpdateInvoice } from "../../api/invoices";

export const fetchInvoices = createAsyncThunk(
  "invoices/fetchInvoices",
  async ({ branchId, year }) => {
    const response = await getInvoices(branchId, year);
    return response;
  }
);

export const createInvoice = createAsyncThunk(
  "invoices/createInvoice",
  async (invoiceData) => {
    const response = await addInvoice(invoiceData);
    return response;
  }
);

export const modifyInvoice = createAsyncThunk(
  "invoices/modifyInvoice",
  async ({ invoiceId, updatedData }) => {
    const response = await apiUpdateInvoice(invoiceId, updatedData);
    return response;
  }
);

const invoicesSlice = createSlice({
  name: "invoices",
  initialState: {
    list: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchInvoices.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInvoices.fulfilled, (state, action) => {
        state.loading = false;
        state.list = action.payload;
      })
      .addCase(fetchInvoices.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(createInvoice.fulfilled, (state, action) => {
        state.list.push(action.payload);
      })
      .addCase(modifyInvoice.fulfilled, (state, action) => {
        const index = state.list.findIndex(
          (invoice) => invoice._id === action.payload._id
        );
        if (index !== -1) {
          state.list[index] = action.payload;
        }
      });
  },
});

export default invoicesSlice.reducer;
