import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCompanies, addCompany, updateCompany } from "../../api/companies";
import { addInvoice } from "../../api/invoices";
import { addPayment } from "../../api/payments";
import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000/";

export const fetchCompanies = createAsyncThunk(
  "companies/fetchCompanies",
  async (selectedbranchId, { rejectWithValue }) => {
    if (!selectedbranchId) {
      return rejectWithValue("Müşteri seçili değil.");
    }

    try {
      const response = await getCompanies(selectedbranchId);
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const createCompany = createAsyncThunk(
  "companies/createCompany",
  async (companyData) => {
    const response = await addCompany(companyData);
    return response;
  }
);

export const modifyCompany = createAsyncThunk(
  "companies/modifyCompany",
  async (companyData) => {
    if (!companyData || !companyData._id) {
      throw new Error("Şirket verisi veya ID eksik.");
    }
    const response = await updateCompany(companyData);
    return response;
  }
);

export const fetchCompanyBalance = createAsyncThunk(
  "companies/fetchCompanyBalance",
  async (companyId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${API_URL}companies/${companyId}/balance`
      );
      return response.data.balance;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addInvoiceAction = createAsyncThunk(
  "companies/addInvoice",
  async ({ companyId, invoiceData }, { rejectWithValue }) => {
    try {
      const response = await addInvoice(companyId, invoiceData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const addPaymentAction = createAsyncThunk(
  "companies/addPayment",
  async ({ companyId, paymentData }, { rejectWithValue }) => {
    try {
      const response = await addPayment(companyId, paymentData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const companiesSlice = createSlice({
  name: "companies",
  initialState: {
    list: [],
    selectedCompany: null,
    loading: false,
    error: null,
  },
  reducers: {
    setSelectedCompany: (state, action) => {
      state.selectedCompany = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCompanies.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchCompanies.fulfilled, (state, action) => {
        state.loading = false;

        if (JSON.stringify(state.list) !== JSON.stringify(action.payload)) {
          state.list = action.payload;
        }
      })
      .addCase(fetchCompanies.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(createCompany.fulfilled, (state, action) => {
        state.list.push(action.payload);
      })

      .addCase(modifyCompany.fulfilled, (state, action) => {
        const index = state.list.findIndex(
          (company) => company._id === action.payload._id
        );
        if (index !== -1) {
          state.list[index] = action.payload;
        }
      })

      .addCase(addInvoiceAction.fulfilled, (state, action) => {
        const companyIndex = state.list.findIndex(
          (company) => company._id === action.payload.companyId
        );
        if (companyIndex !== -1) {
          state.list[companyIndex].invoices.push(action.payload);
        }
      })

      .addCase(addPaymentAction.fulfilled, (state, action) => {
        const companyIndex = state.list.findIndex(
          (company) => company._id === action.payload.companyId
        );
        if (companyIndex !== -1) {
          state.list[companyIndex].payments.push(action.payload);
        }
      });
  },
});

export const { setSelectedCompany } = companiesSlice.actions;

export default companiesSlice.reducer;
