import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchPeriods, setSelectedPeriod } from "../redux/slices/periodSlice";

const PeriodDropdown = () => {
  const dispatch = useDispatch();
  const { periods, selectedPeriod } = useSelector((state) => state.period);

  useEffect(() => {
    dispatch(fetchPeriods());
  }, [dispatch]);

  const handlePeriodSelect = (periodId) => {
    const period = periods.find((p) => p._id === periodId);
    if (period) {
      dispatch(setSelectedPeriod(period));
    }
  };

  return (
    <div>
      <label
        htmlFor="period"
        className="block text-sm font-medium text-gray-300"
      >
        Dönem Seçin
      </label>
      <select
        id="period"
        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 text-black focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        value={selectedPeriod?._id || ""}
        onChange={(e) => handlePeriodSelect(e.target.value)}
      >
        <option value="">Dönem Seçin</option>
        {periods.map((period) => (
          <option key={period._id} value={period._id}>
            {period.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default PeriodDropdown;
