import React, { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx";

const CompanyDetail = ({ formatCurrency }) => {
  const [transactions, setTransactions] = useState([]);

  const selectedCompany = useSelector(
    (state) => state.companies.selectedCompany
  );
  const selectedPeriod = useSelector((state) => state.period.selectedPeriod);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(
          `/companies/${selectedCompany._id}/balance`,
          { params: { year: selectedPeriod.name } }
        );
        const sortedTransactions = response.data.transactions.sort(
          (a, b) => new Date(a.date) - new Date(b.date)
        );
        setTransactions(sortedTransactions);
      } catch (error) {
        console.error("İşlemler alınırken hata:", error);
      }
    };

    fetchTransactions();
  }, [selectedCompany?._id, selectedPeriod]);

  const calculateBalances = (transactions) => {
    let balance = 0;
    return transactions
      .map((transaction) => {
        let isPayment = transaction.type === "payment";
        let isCarryOver = transaction.type === "carry-over";

        let debit = isPayment ? transaction.amount : 0;
        let credit = !isPayment ? transaction.amount : 0;

        balance += credit - debit;

        if (isCarryOver && balance === 0) {
          return null;
        }

        const formattedBalance =
          formatCurrency(Math.abs(balance)) + (balance >= 0 ? " A" : " B");

        return {
          ...transaction,
          debit,
          credit,
          balance: formattedBalance,
          isCarryOver,
        };
      })
      .filter(Boolean);
  };

  const transactionsWithBalances = calculateBalances(transactions);

  const exportToExcel = () => {
    const companyName = selectedCompany?.name.replace(/ /g, "_");

    const companyInfo = [
      ["Şirket Ünvanı:", selectedCompany?.name],
      ["Vergi Numarası:", selectedCompany?.taxNumber],
      ["Adres:", selectedCompany?.address],
      ["Telefon:", selectedCompany?.phoneNumber],
      ["E-Posta:", selectedCompany?.email],
      ["IBAN:", selectedCompany?.iban],
      [],
      ["Tarih", "Belge No", "Borç Tutar", "Alacak Tutar", "Bakiye", "Açıklama"],
    ];

    const transactionData = transactionsWithBalances.map((transaction) => [
      new Date(transaction.date).toLocaleDateString("tr-TR"),
      transaction.invoiceNumber || transaction.documentNumber || "-",
      formatCurrency(transaction.debit),
      formatCurrency(transaction.credit),
      transaction.balance,
      transaction.description,
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([
      ...companyInfo,
      ...transactionData,
    ]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Cari Ekstre");

    XLSX.writeFile(workbook, `${companyName}_Ekstre.xlsx`);
  };

  return (
    <div>
      <div className="flex items-center">
        <button
          onClick={exportToExcel}
          className="px-4 py-2 mt-5 bg-gray-600 text-white hover:bg-gray-400 transition flex items-center justify-center space-x-2 rounded-lg"
        >
          <i className="fas fa-file-excel text-white text-xl"></i>
          <span>Excel</span>
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-100">
            <tr>
              <th className="px-4 py-2">Tarih</th>
              <th className="px-4 py-2">Belge No</th>
              <th className="px-4 py-2 text-right">Borç Tutar</th>
              <th className="px-4 py-2 text-right">Alacak Tutar</th>
              <th className="px-4 py-2 text-right">Bakiye</th>
              <th className="px-4 py-2">Açıklama</th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {transactionsWithBalances?.length > 0 ? (
              transactionsWithBalances?.map((transaction, index) => (
                <tr
                  key={index}
                  className={`${
                    transaction.isCarryOver
                      ? "bg-gradient-to-r from-gray-100 to-gray-200 text-gray-700 font-semibold"
                      : ""
                  }`}
                >
                  <td className="px-4 py-2">
                    {new Date(transaction.date).toLocaleDateString("tr-TR")}
                  </td>
                  <td className="px-4 py-2">
                    {transaction.invoiceNumber ||
                      transaction.documentNumber ||
                      "-"}
                  </td>
                  <td className="px-4 py-2 text-right">
                    {formatCurrency(transaction.debit)}
                  </td>
                  <td className="px-4 py-2 text-right">
                    {formatCurrency(transaction.credit)}
                  </td>
                  <td className="px-4 py-2 text-right text-gray-500">
                    {transaction.balance}
                  </td>
                  <td className="px-4 py-2">{transaction.description}</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" className="text-center text-gray-500 py-4">
                  Bu şirkete ait herhangi bir işlem bulunmamaktadır.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CompanyDetail;
