import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setSelectedCompany } from "../../redux/slices/companiesSlice";
import { FaFileExcel, FaTimes } from "react-icons/fa";
import { FaCopy, FaCheck } from "react-icons/fa";
import { Menu, MenuItem } from "@mui/material";
import * as XLSX from "xlsx";

const CompaniesList = ({ companies, onEdit, onDelete }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const selectedCompany = useSelector(
    (state) => state.companies.selectedCompany
  );
  const [modalOpen, setModalOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const selectedbranch = useSelector((state) => state.branch.selectedbranch);
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(selectedCompany.iban);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const handleSelectCompany = (company) => {
    dispatch(setSelectedCompany(company));
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleGoToDetails = () => {
    navigate(`/companies/${selectedCompany._id}`);
  };

  const sortedCompanies = [...companies].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const formatBalance = (balance) => {
    return new Intl.NumberFormat("tr-TR", {
      style: "currency",
      currency: "TRY",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(Math.abs(balance));
  };

  const exportToExcel = (detailed) => {
    if (!companies || companies.length === 0) return;

    const data = sortedCompanies.map((company) => ({
      Ünvan: company.name,
      Bakiye: company.balance,
      "B/A Durumu": company.balance > 0 ? "A" : company.balance < 0 ? "B" : "-",
      ...(detailed && {
        "Vergi Numarası": company.taxNumber,
        Adres: company.address,
        Telefon: company.phoneNumber,
        Email: company.email,
        IBAN: company.iban,
      }),
    }));

    const worksheet = XLSX.utils.json_to_sheet([]);

    XLSX.utils.sheet_add_aoa(worksheet, [["Şube Bilgileri"]], { origin: "A1" });
    XLSX.utils.sheet_add_aoa(
      worksheet,
      [["Şube Adı:", selectedbranch?.name || ""]],
      { origin: "A2" }
    );
    XLSX.utils.sheet_add_aoa(worksheet, [[""]], { origin: "A3" });

    XLSX.utils.sheet_add_json(worksheet, data, {
      origin: "A4",
      skipHeader: false,
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Şirketler");

    XLSX.writeFile(workbook, "sirketler.xlsx");
  };

  return (
    <div className="overflow-x-auto">
      <div className="flex justify-end mb-4">
        <button
          onClick={(e) => setAnchorEl(e.currentTarget)}
          className="px-4 py-2 bg-green-600 text-white rounded hover:bg-green-700 transition flex items-center"
        >
          <FaFileExcel className="mr-2" /> Excel Aktarımı
        </button>
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem onClick={() => exportToExcel(false)}>
            Ünvan ve Bakiye
          </MenuItem>
          <MenuItem onClick={() => exportToExcel(true)}>Detaylı Liste</MenuItem>
        </Menu>
      </div>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr className="text-center">
            <th className="px-6 py-3 text-xs font-medium text-gray-500 uppercase tracking-wider text-center">
              Ünvan
            </th>
            <th className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              Bakiye
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200 text-4xl">
          {sortedCompanies.map((company) => (
            <tr
              key={company._id}
              className="hover:bg-gray-100 cursor-pointer text-4xl"
              onClick={() => handleSelectCompany(company)}
            >
              <td className="px-6 py-4 whitespace-nowrap font-medium text-gray-900 md:text-xl text-xs">
                {company.name}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-600">
                <div className="flex space-x-2">
                  <span>
                    <span
                      className={
                        company.balance >= 0 ? "text-green-600" : "text-red-600"
                      }
                    >
                      {formatBalance(company.balance)}
                    </span>
                  </span>
                  <span>
                    {company.balance > 0 ? (
                      <span className="text-green-600">A</span>
                    ) : company.balance < 0 ? (
                      <span className="text-red-600">B</span>
                    ) : (
                      "-"
                    )}
                  </span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {modalOpen && selectedCompany && (
        <div
          className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center"
          onClick={handleCloseModal}
        >
          <div
            className="bg-white p-6 rounded-lg shadow-lg w-96 relative"
            onClick={(e) => e.stopPropagation()}
          >
            <button
              onClick={handleCloseModal}
              className="absolute top-2 right-2 text-gray-600 hover:text-gray-900"
            >
              <FaTimes size={20} />
            </button>
            <h2 className="text-lg font-bold mb-4">{selectedCompany.name}</h2>
            <p className="text-sm text-gray-600">
              Vergi Numarası: {selectedCompany.taxNumber}
            </p>
            <p className="text-sm text-gray-600">
              Adres: {selectedCompany.address}
            </p>
            <p className="text-sm text-gray-600">
              Telefon:{" "}
              <a
                href={`tel:${selectedCompany.phoneNumber}`}
                className="underline"
              >
                {selectedCompany.phoneNumber}
              </a>
            </p>
            <p className="text-sm text-gray-600">
              Email:{" "}
              <a href={`mailto:${selectedCompany.email}`} className="underline">
                {selectedCompany.email}
              </a>
            </p>
            <p className="text-sm text-gray-600 flex items-center">
              IBAN: {selectedCompany.iban}
              <button
                onClick={handleCopy}
                className="ml-2 text-blue-600 hover:underline flex items-center"
              >
                {copied ? <FaCheck className="text-green-600" /> : <FaCopy />}
              </button>
            </p>

            <div className="flex justify-between mt-4">
              <button
                onClick={() => {
                  onEdit(selectedCompany);
                  setModalOpen(false);
                }}
                className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition"
              >
                Düzenle
              </button>
              <button
                // onClick={() => onDelete(selectedCompany)}
                className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition"
              >
                Sil
              </button>
              <button
                onClick={handleGoToDetails}
                className="px-4 py-2 bg-fuchsia-800 text-white rounded hover:bg-fuchsia-900 transition"
              >
                Detay
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CompaniesList;
