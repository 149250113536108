import React, { useState, useEffect, useRef } from "react";
import {
  fetchCompanies,
  setSelectedCompany,
} from "../../redux/slices/companiesSlice";
import { setSelectedbranch } from "../../redux/slices/branchSlice";
import CompaniesList from "../../components/Company/CompaniesList";
import CompanyForm from "../../components/Company/CompanyForm";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";
import { FaTimes } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";

const CompaniesPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const selectedbranch = useSelector((state) => state.branch.selectedbranch);
  const branches = useSelector((state) => state.branch.list);
  const companies = useSelector((state) => state.companies.list);
  const selectedCompany = useSelector(
    (state) => state.companies.selectedCompany
  );

  const [showModal, setShowModal] = useState(false);
  const [searchName, setSearchName] = useState("");
  const [isBranchDropdownOpen, setIsBranchDropdownOpen] = useState(false);
  const branchDropdownRef = useRef(null);

  useEffect(() => {
    if (selectedbranch?._id) {
      dispatch(fetchCompanies(selectedbranch._id));
    }
  }, [selectedbranch, dispatch]);

  const handleBranchSelect = (branch) => {
    dispatch(setSelectedbranch(branch));
    setIsBranchDropdownOpen(false);
  };

  const handleSelectCompany = (company) => {
    dispatch(setSelectedCompany(company));
    navigate(`/companies/${company._id}`);
  };

  const handleCompanyAdded = (newCompany) => {
    setShowModal(false);
    dispatch(fetchCompanies(selectedbranch._id));
  };

  const handleEditCompany = (company) => {
    dispatch(setSelectedCompany(company));
    setShowModal(true);
  };

  const handleCompanyUpdated = () => {
    setShowModal(false);
    dispatch(fetchCompanies(selectedbranch._id));
  };

  return (
    <>
      <Helmet>
        <title>Şirketler - Etkin Cari</title>
        <meta
          name="description"
          content="Şubelerinizin ticari işlemlerini ve cari hesaplarını takip edin."
        />
        <meta
          name="keywords"
          content="şube yönetimi, ticari işlemler, borç alacak, cari hesap"
        />
      </Helmet>
      <div className="p-1 flex flex-col items-center h-screen">
        <h1 className="text-2xl font-bold mb-4 text-center">Şirketler</h1>
        {selectedbranch ? (
          <>
            <div className="w-full max-w-lg bg-white rounded-lg shadow-md overflow-hidden">
              <div className="p-4 border-b border-gray-300">
                <div className="relative">
                  <input
                    type="text"
                    placeholder="Ünvan Ara"
                    value={searchName}
                    onChange={(e) => setSearchName(e.target.value)}
                    className="border z-10 rounded-lg w-full p-2 mb-2 pr-10"
                  />
                  {searchName && (
                    <button
                      onClick={() => setSearchName("")}
                      className="absolute inset-y-1 right-3 flex items-center pl-2"
                    >
                      <FaTimes className="text-gray-400 hover:text-gray-600" />
                    </button>
                  )}
                </div>
                <button
                  className="mt-4 px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transition w-full"
                  onClick={() => {
                    dispatch(setSelectedCompany(null));
                    setShowModal(true);
                  }}
                >
                  Şirket Ekle
                </button>
              </div>
              <div className="border-b border-gray-300 pb-4 mb-4 max-h-[75vh] overflow-y-auto">
                <CompaniesList
                  companies={companies.filter((c) =>
                    c.name.toLowerCase().includes(searchName.toLowerCase())
                  )}
                  onSelect={handleSelectCompany}
                  onEdit={handleEditCompany}
                />
              </div>
            </div>
          </>
        ) : (
          <div className="flex flex-col items-center justify-center min-h-[60vh] text-center">
            <div className="text-gray-500 mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-20 h-20"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M3.75 21h16.5M3.75 3v18m16.5-18v18M8.25 6h7.5M8.25 10.5h7.5M8.25 15h7.5"
                />
              </svg>
            </div>
            <h2 className="text-xl font-bold text-gray-800">
              Seçili bir şube bulunamadı
            </h2>
            <p className="text-gray-500 mt-2">
              Şirketleri görüntülemek için bir şube seçmeniz gerekiyor.
            </p>
            <div className="relative mt-4" ref={branchDropdownRef}>
              <button
                onClick={() => setIsBranchDropdownOpen(!isBranchDropdownOpen)}
                className="bg-gray-700 text-white px-6 py-2 rounded-md hover:bg-gray-600 transition"
              >
                {selectedbranch ? selectedbranch.name : "Şube Seçiniz"}
              </button>
              {isBranchDropdownOpen && (
                <ul className="absolute left-0 mt-2 bg-white text-gray-800 shadow-lg rounded-md w-48 z-40">
                  {branches?.length > 0 ? (
                    branches.map((branch) => (
                      <li key={branch._id}>
                        <button
                          onClick={() => handleBranchSelect(branch)}
                          className="w-full px-4 py-2 text-left hover:bg-gray-100"
                        >
                          {branch.name}
                        </button>
                      </li>
                    ))
                  ) : (
                    <li className="px-4 py-2 text-gray-500">Şube bulunamadı</li>
                  )}
                </ul>
              )}
            </div>
          </div>
        )}
        <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
          <CompanyForm
            onCompanyAdded={handleCompanyAdded}
            initialData={selectedCompany}
            branchId={selectedbranch?._id}
            onUpdateCompany={handleCompanyUpdated}
          />
        </Modal>
      </div>
    </>
  );
};

export default CompaniesPage;
