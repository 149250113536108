import axios from "axios";

const API_URL = process.env.REACT_APP_API_URL;

export const getCompanies = async (branchId) => {
  try {
    const response = await axios.get(`${API_URL}companies`, {
      params: { branchId },
    });
    return response.data;
  } catch (error) {
    throw new Error("Error fetching companies: " + error.message);
  }
};

export const addCompany = async (company, branchId) => {
  try {
    const response = await axios.post(`${API_URL}companies`, company, {
      headers: { "branch-id": branchId },
    });
    return { message: "Şirket başarıyla eklendi.", company: response.data };
  } catch (error) {
    console.error(
      "Şirket ekleme hatası:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const updateCompany = async (companyData) => {
  try {
    const response = await axios.put(
      `${API_URL}companies/${companyData._id}`,
      companyData,
      { headers: { "Content-Type": "application/json" } }
    );
    return response.data;
  } catch (error) {
    console.error("Şirket güncelleme hatası:", error.message);
    throw error;
  }
};

export const deleteCompany = async (companyId, branchId) => {
  try {
    const response = await axios.delete(`${API_URL}companies/${companyId}`, {
      headers: { "branch-id": branchId },
    });
    return {
      message: "Şirket ve ilişkili faturalar başarıyla silindi.",
      data: response.data,
    };
  } catch (error) {
    console.error(
      "Şirket silme hatası:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const getCompanyDetails = async (id, branchId) => {
  try {
    const response = await axios.get(`${API_URL}companies/${id}`, {
      headers: { "branch-id": branchId },
    });
    return response.data;
  } catch (error) {
    console.error(
      "Şirket detayları getirme hatası:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};

export const deactivateCompany = async (companyId, branchId) => {
  try {
    const response = await axios.put(
      `${API_URL}companies/${companyId}/deactivate`,
      {},
      {
        headers: { "branch-id": branchId },
      }
    );
    return {
      message: "Şirket başarıyla deaktif hale getirildi.",
      company: response.data,
    };
  } catch (error) {
    console.error(
      "Şirket deaktif etme hatası:",
      error.response ? error.response.data : error.message
    );
    throw error;
  }
};
