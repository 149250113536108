import React, { useState } from "react";
import DatePicker, { setDefaultLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addPayment } from "../../api/payments"; // Ödeme ekleme API fonksiyonu
import { toast } from "react-toastify";
import { registerLocale } from "react-datepicker";
import tr from "date-fns/locale/tr";
import { useSelector } from "react-redux";

registerLocale("tr", tr);
setDefaultLocale("tr");

const generateDocumentNumber = () => `PAY-${Date.now().toString().slice(-6)}`;

const CompanyPaymentForm = ({ onPaymentAdded, companyId, closeModal }) => {
  const [documentNumber, setDocumentNumber] = useState(
    generateDocumentNumber()
  );
  const [date, setDate] = useState(null);
  const [amount, setAmount] = useState("");
  const [description, setDescription] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const selectedbranch = useSelector((state) => state.branch.selectedbranch);
  const selectedPeriod = useSelector((state) => state.period.selectedPeriod);

  const currentYear = new Date().getFullYear().toString();
  const isCurrentYear = selectedPeriod?.name === currentYear;

  const minDate = selectedPeriod?.startDate
    ? new Date(selectedPeriod.startDate)
    : null;

  const maxDate = isCurrentYear
    ? new Date()
    : selectedPeriod?.endDate
    ? new Date(new Date(selectedPeriod.endDate).toISOString().split("T")[0])
    : null;

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (isSubmitting) return;

    if (!selectedbranch?._id) {
      toast.error("Lütfen bir müşteri seçin.", {
        position: "top-right",
        autoClose: 3000,
      });
      return;
    }

    setIsSubmitting(true);
    const newPayment = {
      documentNumber: documentNumber.toUpperCase(),
      date,
      branchId: selectedbranch._id,
      amount: Number(amount),
      description: description.toUpperCase(),
      companyId,
    };

    try {
      await addPayment(newPayment);
      closeModal();
      setDocumentNumber(generateDocumentNumber());
      setDate(null);
      setAmount("");
      setDescription("");

      onPaymentAdded();
    } catch (error) {
      console.error("Ödeme eklerken hata:", error);
      toast.error("Ödeme eklenirken bir hata oluştu.", {
        position: "top-right",
        autoClose: 3000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="space-y-4 border border-gray-300 rounded shadow-md p-5"
    >
      <div className="mb-4">
        <label className="block">Belge No</label>
        <input
          type="text"
          value={documentNumber}
          onChange={(e) => setDocumentNumber(e.target.value)}
          required
          className="w-full border px-4 py-2"
          maxLength={16}
          readOnly
        />
      </div>

      <div>
        <label className="block">Tarih</label>
        <DatePicker
          selected={date}
          onChange={(date) => setDate(date)}
          dateFormat="dd/MM/yyyy"
          locale="tr"
          className="w-full border px-4 py-2"
          required
          minDate={minDate}
          maxDate={maxDate}
          placeholderText="Tarih seçiniz"
        />
      </div>

      <div>
        <label className="block">Tutar</label>
        <input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          required
          className="w-full border px-4 py-2"
          step="0.01"
          min="0.01"
          max="100000000"
        />
      </div>

      <div>
        <label className="block">Açıklama</label>
        <input
          type="text"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          required
          className="w-full border px-4 py-2"
          maxLength={50}
        />
      </div>

      <div className="flex justify-between">
        <button
          type="button"
          onClick={closeModal}
          className="bg-gray-500 text-white px-4 py-2 rounded-lg"
        >
          Kapat
        </button>
        <button
          type="submit"
          className="bg-red-600 px-2 py-1 text-white rounded-lg hover:bg-red-300 hover:text-black transition"
          disabled={isSubmitting}
        >
          {isSubmitting ? "Yükleniyor..." : "Ödeme Ekle"}
        </button>
      </div>
    </form>
  );
};

export default CompanyPaymentForm;
