import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
} from "firebase/auth";
import { loginSuccess, loginFailure, logout } from "./slices/authSlice";
import { resetBranchState } from "./slices/branchSlice";
import { auth } from "../auth/firebase";

export const loginUser = (email, password) => async (dispatch) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;

    dispatch(
      loginSuccess({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName || "Kullanıcı",
        photoURL: user.photoURL || null,
      })
    );
  } catch (error) {
    let errorMessage = "Giriş yaparken bir hata oluştu. Lütfen tekrar deneyin.";

    if (error.code === "auth/user-not-found") {
      errorMessage = "Bu e-posta adresi ile kayıtlı bir kullanıcı bulunamadı.";
    } else if (error.code === "auth/wrong-password") {
      errorMessage = "Yanlış şifre. Lütfen tekrar deneyin.";
    } else if (error.code === "auth/invalid-email") {
      errorMessage = "Geçersiz e-posta adresi.";
    }

    dispatch(loginFailure(errorMessage));
    console.error("Hata oluştu:", error.message);
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    await signOut(auth);

    dispatch(logout());

    dispatch(resetBranchState());
  } catch (error) {
    console.error("Hata oluştu:", error.message);
  }
};
export const checkAuthState = () => (dispatch) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      dispatch(
        loginSuccess({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName || "Kullanıcı",
          photoURL: user.photoURL || null,
        })
      );
    } else {
      dispatch(logout());
    }
  });
};
