import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const fetchPeriods = createAsyncThunk(
  "period/fetchPeriods",
  async () => {
    const response = await axios.get("/period");
    return response.data;
  }
);

const periodSlice = createSlice({
  name: "periods",
  initialState: {
    periods: [],
    selectedPeriod: null, 
  },
  reducers: {
    setSelectedPeriod: (state, action) => {
      state.selectedPeriod = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchPeriods.fulfilled, (state, action) => {
      state.periods = action.payload;
      if (action.payload.length > 0 && !state.selectedPeriod) {
        state.selectedPeriod = action.payload.find((period) => {
          const today = new Date();
          return (
            new Date(period.startDate) <= today &&
            new Date(period.endDate) >= today
          );
        });
      }
    });
  },
});

export const { setSelectedPeriod } = periodSlice.actions;
export default periodSlice.reducer;
